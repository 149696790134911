.gallery_gear{
    left: 7.5vmax;
}
.gallery_heading{
    font-weight: 500;
    font-size: 1.7vmax;
    line-height: 2.4vmax;
    color: #348FE2;
}
.gallery_card{
    display: flex;
    flex-direction: column;
    background: #00B2FF;
    width: fit-content;
    filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.3));
}
.gallery_card_img_p{
    background: #00B2FF;
    padding-top: 0.45vmax;
}
.gallery_card_img_p img{
    width: 20vmax;
    object-fit: cover;
    height: 14.28vmax;
}
.gallery_card_heading{
    font-weight: 500;
    font-size: 1.5vmax;
    color: #FFFFFF;
    padding: 0.3vmax 1vmax;
}
.gallery_parent_swiper .swiper {
    width: calc(100% - 92px);
    height: 100%;
    /* margin: 0px 46px; */
}  
.gallery_parent_swiper .swiper-slide {
    width: 20vmax !important;
    margin: 0 1vmax !important;
}
.gallery_parent_swiper .swiper-container{
    position: relative;
    background: #FBFBFB !important;
    padding: 2.1vmax 2.4vmax;
    background: #FBFBFB;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
    margin-top: 1.25vmax;
    margin-bottom: 5.6vmax;
    overflow-y: hidden !important;
}
.gallery_parent_swiper .swiper-button-prev, .gallery_parent_swiper .swiper-button-next{
    color: #348FE2 !important;
    padding: 10px;
    aspect-ratio: 1/1;
    transform: scale(0.8);
    transition: 0.3s;
    opacity: 1 !important;
    pointer-events: all !important;
}
.gallery_parent_swiper .swiper-button-prev{
    height: calc(150% + 4.5vmax);
    background: #F1F1F1 !important;
    transform: scale(0.8) translateY(calc(-50% - 1.8vmax)) translateX(-42%);
    width: 4.2vmax;
}
.gallery_parent_swiper .swiper-button-next{
    height: calc(150% + 4.5vmax);
    background: #F1F1F1 !important;
    transform: scale(0.8) translateY(calc(-50% - 1.8vmax)) translateX(42%);
    width: 4.2vmax;
}
.gallery_parent_swiper .swiper-button-prev::after, .gallery_parent_swiper .swiper-button-next::after{
    transform: scale(0.6);
    aspect-ratio: 1/1;
}