* {
  box-sizing: border-box;
}
.contactUs_gear {
  left: 11.7vmax;
}
.details {
  display: flex;
  gap: 3em;
  flex-wrap: wrap;
}
.msg {
  flex: 0.6;
}
.address {
  flex: 0.4;
}
.form ::placeholder {
  color: rgba(0, 0, 0, 0.5);
}
input,
textarea {
  font-family: Cerebri Sans Book;
  overflow: hidden;
  font-weight: 274;
  font-size: 1.2em;
  outline: none;
  background: transparent;
  padding: 0.5em 1.3em 0.5em 1.3em;
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  width: 100%;
  color: rgb(0, 0, 0);
}
input:focus,
textarea:focus {
  border: 2px solid black;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 1em;
  padding: 2em 3em 2em 3em;
  background: #eaf5ff;
  border-radius: 20px;
}
.nameAndEmail {
  display: flex;
  width: 100%;
  gap: 1em;
}
.subAndMsg {
  display: flex;
  flex-direction: column;
  gap: 1em;
}
.buttons {
  display: flex;
  justify-content: space-evenly;
}
.buttons > input {
  width: auto;
  background: transparent;
  color: #00b2ff;
  outline: none;
  border: 2px solid #00b2ff;
  border-radius: 12px;
  font-weight: 400;
  padding: 0.3em 1em 0.3em 1em;
}
.buttons > input:nth-child(1) {
  padding: 0.3em 3em 0.3em 3em;
}
.buttons > input:hover {
  background-color: #00b2ff;
  color: white;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.asmeContact_details {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1em;
  margin-top: 2em;
  position: relative;
}
.asmeContact_details::after {
  content: "";
  background-color: #00b2ff;
  position: absolute;
  left: 49%;
  width: 2px;
  height: 120%;
  z-index: 100;
}
.asmeContact_details a,
.asmeContact_details p {
  font-weight: 400;
  font-size: 16px;
}
.number,
.email {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.number > div,
.email > div {
  display: flex;
  flex-direction: column;
  gap: 0.7em;
}
.icons {
  background-color: #00b2ff;
  color: white;
  font-size: 3vmax;
  padding: 0.2em;
  border-radius: 50%;
  margin-right: 1em;
}
.asmeContact_details a {
  text-decoration: none;
  color: rgba(38, 38, 38, 0.74);
}
.address {
  display: flex;
  flex-direction: column;
}

.snapshot {
  display: flex;
  flex: 0.9;
  margin-bottom: 2em;
}
.address_details {
  flex: 0.1;
}
.img,
.snapshot iframe {
  width: 100%;
  height: 100%;
}
.snapshot iframe {
  border-radius: 8px;
}
.address_details > h2,
.address_details > p {
  font-style: normal;
  font-weight: 400;
}
.address_details > h2 {
  color: #348fe2;
  font-size: 2.4vmax;
  margin-bottom: 0.5em;
}
.address_details > p {
  font-size: 2vmax;
  margin-bottom: 2em;
}
p {
  padding: 0;
  margin: 0;
}

@media screen and (max-width: 700px) {
  .details {
    flex-direction: column;
  }
  .address {
    flex-direction: column-reverse;
  }
  .snapshot iframe {
    height: 300px;
  }
  .buttons > input:nth-child(1) {
    padding: 0.3em 2em 0.3em 2em;
  }
}
@media screen and (max-width: 400px) {
  .snapshot iframe {
    height: 200px;
  }
  input,
  textarea {
    font-size: 0.8rem;
  }
}
@media screen and (max-width: 500px) {
  .form {
    padding-left: 2em;
    padding-right: 2em;
  }
  .buttons > input {
    padding: 0.3em 0.5em 0.3em 0.5em;
  }
  input,
  textarea {
    padding: 0.5em 0.8em 0.5em 0.8em;
  }
  .contactUsPage {
    padding: 1em 1em 2em 1em;
  }
  .nameAndEmail {
    flex-direction: column;
  }
}
@media screen and (max-width: 340px) {
  .asmeContact_details::after {
    left: 0;
    height: 2px;
    width: 100%;
  }
  .asmeContact_details {
    flex-direction: column;
  }
  .buttons > input:nth-child(1) {
    padding: 0.3em 0.5em 0.3em 0.5em;
  }
}
