.event_gear_prop{
    height: 2.5vmax;
    position: relative;
    z-index: -1;
    top: -0.8vmax;
    left: 12vmax;
    padding-bottom: 0.6vmax;
}
.event_content{
    display: grid;
    grid-template-columns: 65% 35%;
}
.event_right_content{
    width: 100%;
    position: relative;
}
.event_prop{
    object-fit: cover;
    width: 100%;
    margin: 0 1vmax 1vmax 1vmax !important;
    float: right;
    box-shadow: 0 0 0.8vmax 0 lightgrey;
}
.card{
    text-align: center;
    box-shadow: 0 0 1vmax 0 lightgray;
    border-radius: 1vmax;
    margin-top: 0.5vmax;
    margin-bottom: 2vmax;
    background-color: #DBF4FF;
}
.card_head{
    padding-top: 0.5vmax;
    padding-bottom: 0.5vmax;
    color: white;
    background-color: #00B2FF;
    font-size: 1.5vmax;
    border-radius: 1vmax;
    box-shadow: 0 0.8vmax 0.8vmax -0.8vmax #348FE2;
}
.card_content{
    color: #348FE2;
    font-size: 1.2vmax;
    padding: 1vmax 1.5vmax 1vmax 1.5vmax;
}
.irc_image{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

@media(max-width: 650px){
    .event_content{
        display: grid;
        grid-template-columns: unset;
    }    
    .event_prop{
        margin: 1vmax 1vmax 1vmax 0vmax !important;
        height: 28vmax;
    }
    .irc_2017{
        width: 100%;
        height: 100% !important;
        position: relative;
        right: 1vmax;
        object-fit: cover;
    }
    .irc_image{
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}