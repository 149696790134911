@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500&display=swap);
*{
  margin: 0;
}
body {
  margin: 0;
  font-family: Cerebri Sans Book;
}

*{
    scroll-behavior: smooth;
}
.home_banner{
    display: flex;
    height: 40vmax;
    position: relative;
}
.left_banner{
    padding-left: 6vmax;
    padding-right: 6vmax;
    text-align: center;
}
.main_head{
    color: #55A4ED;
    font-weight: 600;
    font-size: 4vmax;
    padding-top: 5vmax;
}
.sub_head{
    color: #55A4ED;
    font-weight: 600;
    font-size: 2.5vmax;
}
.banner_content{
    color: #6090BC;
    font-size: 1.5vmax;
}
.drop_style{
    background-color: rgba(0, 178, 255, 0.18);
    text-align: center;
    position: absolute;
    bottom: 0;
    width: 43.3vmax;
}
.home_drop_down{
    text-decoration: none;
    color: #55A4ED;
}
.homeimg_prop{
    height: 40vmax;
    float: right;
}
.scroll_down_button{
    position: relative;
    z-index: 2;
    top: -0.8vmax;
    display: flex;
    justify-content: center;
}
.proj_card{
    display: flex;
    padding-left: 10vmax;
    padding-right: 10vmax;
    padding-top: 2vmax;
    padding-bottom: 3vmax;
    align-items: center;
}
.left_proj{
    background-color: #00B2FF;
    flex: 0.3 1;
    height: 30vmax;
}
.right_proj{
    flex: 0.7 1;
    height: 25vmax;
    box-shadow: 0 0 0.8vmax 0 lightgray;
    display: flex;
    align-items: center;
}
.proj_img_prop{
    height: 20vmax;
    position: relative;
    left: -12vmax;
    box-shadow: 0px 0px 5px #00B2FF;
}
.proj_detail{
    position: relative;
    left: -5vmax;
}
.proj_head{
    float: left;
    color: #348FE2;
    font-size: 2vmax;
    font-weight: 600;
    padding-bottom: 1vmax;
}
.proj_des{
    float: right;
    font-size: 1.2vmax;
    line-height: 1.6;
}
.read_more_button{
    all: unset;
    color: white;
    background-color: #00B2FF;
    cursor: pointer;
    padding: 0.5vmax 1.5vmax 0.5vmax 1.5vmax;
    float: right;
    margin-top: 2vmax;
    margin-right: -5vmax;
}

@media(max-width: 780px){
    .right_banner{
        display: none;
    }
    .home_banner{
        background-image: url(../../static/media/home_img.ebb4c624.png);
        background-size: cover;
    }
    .banner_content{
        font-weight: 600;
    }
    .drop_style{
        display: none;
    }
}

@media(max-width: 615px){
    .proj_card{
        display: flex;
        flex-direction: column;
    }
    .left_proj{
        display: none;
    }
    .right_proj{
        flex-direction: column;
        text-align: center;
    }
    .proj_img_prop{
        left: 0;
        padding: 2.5vmax;
    }
    .proj_detail{
        left: 0;
        padding-left: 3vmax;
        padding-right: 3vmax;
    }
    .proj_head{
        float: none;
    }
    .proj_des{
        font-size: 1.5vmax;
    }
    .read_more_button{
        margin-right: -3vmax;
        margin-bottom: 1vmax;
    }
}

@font-face {
    font-family: 'Cerebri Sans Book';
    font-style: normal;
    font-weight: normal;
    src: url("../../static/media/Cerebri Sans Book.1d6bf2e8.woff");
}
.navbar{
    display: flex;
    background-color: white;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    top: 0;
    z-index: 999;
    padding-top: 0.4vmax;
    padding-bottom: 0.4vmax;
    padding-left: 3vmax;
    box-shadow: 0 0.2vmax 0.2vmax lightgray;
    transition-delay: 0.15;
    max-width: 100vw;
}
.logo_prop{
    width: 5vmax;
    object-fit: contain;
    padding-right: 2vmax;
}
.right{
    width: 85vmax;
    background-color: #00B2FF;
    display: flex;
}
.mid-box_1{
    background-color: #D6F3FF;
    width: 1.5vmax;
}
.mid-box_2{
    background-color: #99E0FF;
    width: 1.5vmax;
}
.nav-heading > .option{
    text-decoration: none;
    list-style: none;
    padding: 1vmax 1.5vmax 1vmax 1.5vmax;
    color: white;
    font-family: 'Cerebri Sans Book';
    font-size: 1.3vmax;
}
.option > a{
    text-decoration: none;
    color: white;
    font-family: 'Cerebri Sans Book';
    font-size: 1.3vmax;
}
.nav-heading > .option:hover{
    background-color: #348FE2;
}
.nav-heading{
    display: flex;
}
.hamburger-menu{
    display: none;
}
.box{
    background-color: #00B2FF2E;
    height: 1vmax;
}

@media(max-width: 675px){
    .mid-box_2{
        display: none;
    }
    .right{
        display: none;
    }
    .mobile-right{
        display: flex;
        align-items: flex-start;
    }
    .nav-heading{
        display: flex;
        flex-direction: column;
        position: absolute;
        z-index: 990;
        background-color: #00B2FF;
        padding: 0;
        align-items: center;
        left: 0;
        width: 100%;
        top: 5vmax;
    }
    .nav-heading > li:hover{
        background-color: #00B2FF;
        font-weight: 600;
    }
    .nav-heading > li > a{
        font-size: 1.5vmax;
    }
    .hamburger-menu{
        display: flex;
        align-items: center;
        padding-right: 4vmax;
    }
    .hamburger-menu >a{
        text-decoration: none;
        color: #002D62;
    }
}
h1{
    font-size: 2.4vmax;
    font-weight: 600;
}
.about_style{
    padding-top: 1.5vmax;
    padding-left: 3vmax;
    padding-right: 3vmax;
    overflow: hidden;
}
::-webkit-scrollbar {
    width: 0;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
}
.gear_prop{
    height: 2.5vmax;
    position: relative;
    z-index: -1;
    top: -0.8vmax;
    left: 9vmax;
    padding-bottom: 0.6vmax;
}
.sample_prop{
    height: 28vmax;
    float: right;
    margin: 0 1vmax 1vmax 3vmax;
    box-shadow: 0 0 0.8vmax 0 lightgray;
}
h2{
    font-size: 1.6vmax;
    color: #348FE2;
    line-height: 3vmax;
}
.main_content{
    font-weight: 500;
    font-size: 1.3vmax;
    padding-bottom: 0.6vmax;
}
.content{
    font-weight: 400;
    font-size: 1.1vmax;
    padding-bottom: 1.2vmax;
}
.divider{
    width: 12vmax;
    margin-left: 0;
}
.first{
    padding-bottom: 3vmax;
}
.second{
    padding-bottom: 1.8vw;
}
@media(max-width: 560px){
    .sample_prop{
        display: none;
    }
}

.event_gear_prop{
    height: 2.5vmax;
    position: relative;
    z-index: -1;
    top: -0.8vmax;
    left: 12vmax;
    padding-bottom: 0.6vmax;
}
.event_content{
    display: grid;
    grid-template-columns: 65% 35%;
}
.event_right_content{
    width: 100%;
    position: relative;
}
.event_prop{
    object-fit: cover;
    width: 100%;
    margin: 0 1vmax 1vmax 1vmax !important;
    float: right;
    box-shadow: 0 0 0.8vmax 0 lightgrey;
}
.card{
    text-align: center;
    box-shadow: 0 0 1vmax 0 lightgray;
    border-radius: 1vmax;
    margin-top: 0.5vmax;
    margin-bottom: 2vmax;
    background-color: #DBF4FF;
}
.card_head{
    padding-top: 0.5vmax;
    padding-bottom: 0.5vmax;
    color: white;
    background-color: #00B2FF;
    font-size: 1.5vmax;
    border-radius: 1vmax;
    box-shadow: 0 0.8vmax 0.8vmax -0.8vmax #348FE2;
}
.card_content{
    color: #348FE2;
    font-size: 1.2vmax;
    padding: 1vmax 1.5vmax 1vmax 1.5vmax;
}
.irc_image{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

@media(max-width: 650px){
    .event_content{
        display: grid;
        grid-template-columns: unset;
    }    
    .event_prop{
        margin: 1vmax 1vmax 1vmax 0vmax !important;
        height: 28vmax;
    }
    .irc_2017{
        width: 100%;
        height: 100% !important;
        position: relative;
        right: 1vmax;
        object-fit: cover;
    }
    .irc_image{
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}
.eventpage_gear{
    left: 6.5vmax;
}
.main_event_card{
    display: flex;
    justify-content: space-between;
    padding: 1vmax 0;
}


.left_event_card{
    /*background-color: blueviolet;*/
    width: 30%;
}
.bottom_img{
    width: 23vmax;
    height: 18.5vmax;
    background-color: white;
    box-shadow: 0 0 0.8vmax 0 lightgray;
    position: relative;
    margin-top: 0;
    margin-left: 0;
}
.middle_img{
    width: 23vmax;
    height: 18.5vmax;
    background-color: white;
    box-shadow: 0 0 0.8vmax 0 lightgray;
    position: relative;
    margin-top: -17vmax;
    margin-left: 1.5vmax;
}
.top_img{
    width: 23vmax;
    height: 18.5vmax;
    box-shadow: 0 0 0.8vmax 0 lightgray;
    position: relative;
    margin-top: -17vmax;
    margin-left: 3vmax;
}
.top_img>img{
    object-fit: cover;
    width: 23vmax;
    height: 18.5vmax;
}


.right_event_card{
    /*background-color: chartreuse;*/
    width: 65%;
    /*display: flex;
    flex-direction: column;*/
    box-shadow: 0 0 0.8vmax 0 lightgray;
}
.event_card_title{
    background-color: #348FE2;
    color: white;
    padding: 0.5vmax 2vmax;
    font-size: 1.3vmax;
}
.event_card_date{
    background-color: #00B2FF;
    color: white;
    padding: 0.5vmax 2vmax;
    font-size: 1.3vmax;
}
.event_card_des{
    color: #0E4170;
    height: 9.5vmax;
    padding: 2vmax 2vmax;
    font-size: 1.17vmax;
    display: flex;
    align-items: center;
}
.event_card_more{
    background-color: #00B2FF24;
    color: #0056A5;
    display: flex;
    justify-content: center;
    padding: 0.2vmax 2vmax;
    font-size: 2.5vmax;
    height: 2.5vmax;
}
.event_card_more>a{
    all: unset;
    display: flex;
    align-items: center;
}
.event_card_more>a:hover{
    cursor: pointer;
}

@media(max-width: 650px){
    .main_event_card .left_event_card{
        display: none;
    }
    .right_event_card{
        width: 100% !important;
    }
}
.participation_gear{
    left: 13.5vmax;
}
.team_parent{
    position: relative;
    width: 100vw;
}
.team_svg_parent{
    display: flex;
    align-items: flex-end;
}
.team_svg_parent svg{
    height: 2.5vmax;
    position: relative;
    /* z-index: -1; */
    top: 1.3vmax;
    left: -1vmax;
    /* transform: translate(-1.5vmax, 1.6vmax); */
}
.team_of_2019_20{
    font-style: normal;
    font-size: 2.4vmax;
    font-weight: 600;
    margin-left: 46px;
    margin-top: 46px;
}

.team_parent_swiper .swiper {
    width: calc(100% - 92px);
    height: 100%;
    margin: 0px 46px;
}  
.team_parent_swiper .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 50px;
}
.team_parent_swiper .swiper-slide img {
    display: block;
    width: 100%;
    height: 33vmax;
    object-fit: cover;
    position: relative;
}
.team_parent_swiper .swiper-container{
    margin: 0px 46px !important;
    /* background: url(500_F_225403009_RGr662fPwQPR6guugsPIhMhnLNjWXjUp.jpg); */
-webkit-filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.25));
        filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.25));
}
.team_mem_card_heading{
    font-style: normal;
    font-weight: 500;
    /* font-size: 25px; */
    font-size: 1.9vmax;
    line-height: 32px;
    color: #348FE2;
    margin: 0px 46px;
    margin-top: 40px;
    margin-bottom: 16px;
}
.team_mem_card_parent{
    margin: 0px 46px;
    display: flex;
    flex-wrap: wrap;
    max-width: 100vw;
    justify-content: space-between;
}
.team_parent_swiper .swiper-button-prev, .team_parent_swiper .swiper-button-next{
    background: #DDDDDD;
    color: #fff !important;
    padding: 10px;
    border-radius: 50%;
    aspect-ratio: 1/1;
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    transition: 0.3s;
    height: 4vmax !important;
    width: 4vmax !important;
    margin-top: 0 !important;
}
.team_parent_swiper .swiper-button-prev:hover, .team_parent_swiper .swiper-button-next:hover{
    box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.411);
    -webkit-filter: brightness(70%);
            filter: brightness(70%);
}
.team_parent_swiper .swiper-button-prev:active, .team_parent_swiper .swiper-button-next:active{
    -webkit-filter: brightness(60%);
            filter: brightness(60%);
}
.team_parent_swiper .swiper-button-prev::after, .team_parent_swiper .swiper-button-next::after{
    -webkit-transform: scale(0.6);
            transform: scale(0.6);
    aspect-ratio: 1/1;
}

.team_sep-render{
    margin: 0px 46px;
    display: flex;
    flex-wrap: wrap;
    max-width: 100vw;
    /* justify-content: left; */
}




.memb_car_card{
    display: flex;
    align-items: center;
    min-width: 20vw;
    /* width: 40vw; */
    margin-right: 35px;
    position: relative;
    z-index: 10;
    flex-wrap: wrap;
    background-color: #fff;
    box-shadow: none;
    flex-direction: column;
    margin-bottom: 35px;
}
.card_fd_fix{
    flex-direction: row;
    /* width: 40vw; */
}
.memb_car_content{
    font-family: 'Montserrat', sans-serif;
    color: white;
    background-color: #00B2FF;
    height: 150px;
    width: 20vw;
    min-width: 220px;
    text-align: left;
    /* padding-left: 2px; */
    position: relative; 
    box-shadow: 0px 0px 30px #00000030;
}
.memb_car_content h2{
    font-size: 1.1rem;
    color: white;
    font-weight: 700;
    padding-left: 20px;
    max-width: 210px;
}
.memb_car_content h3{
    font-weight: 400;
    padding-left: 20px;
    max-width: 210px;
    font-size: 1rem;
}
.memb_car_image{
    display: flex;
    /* height: 210px; */
    width:20vw;
    min-width: 220px;
    flex-direction: column;
    justify-content: center;
    box-shadow: 0px 0px 30px #00000030;
}
.memb_car_card img{
    height: 100%;
    width: auto;
    object-fit: cover;
    /* transform-origin: 20%; */
    /* transform: translate(0,10px); */
    /* transform: rotate(45deg); */
}
.memb_car_image hr{
    border: none;
    margin: 0;
    background-color: #00B2FF;
    height: 5px;
}
.memb_car_icons_p{
    display: flex;
    flex-direction: column;
    position: absolute;
    right: -18px;
    height: 135px;
    justify-content: space-between;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
}
.memb_car_icons:hover{
    color: white;
    background-color: #00B2FF;
    border-color: white;
}
.memb_car_icons{
    border: 3.5px solid #00B2FF ;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: white;
    color: #00B2FF;
    font-size: 24px;
    text-align: center;
    box-shadow: 0px 0px 4px #888888;
    cursor: pointer;
    

}
.memb_car_icons a{

    text-decoration: none;
    color: inherit;
   
}
.svg-inline--fa{
    padding: 0.1rem;
}
@media(max-width:700px) {
    .memb_car_card{
        flex-direction: column !important;
        width: 100% !important;
        margin-left: 30px;
        
    }
    .memb_car_content h2{
        color: white;
        font-weight: 700;
        padding-left: 20px;
        font-size: 20px;
        padding-top: 10px;
        max-width: 80%;
    }
    .memb_car_content h3{
        font-size: 15px;
        padding-top: 4px;
        max-width: 80%;
    }
    
}







.search_prop{
    background-color: #00B2FF;
    padding-top: 1vmax;
    padding-bottom: 1vmax;
    display: flex;
    justify-content: center;
    font-size: 1.2vmax;
    margin-top: 3vmax;
}
.search_box{
    all: unset;
    border: 0.2vmax solid #0056A5;
    background-color: white;
    height: 3vmax;
    width: 45vmax;
    border-right: 0;
    padding-left: 1vmax;
    box-shadow: inset 0px 0px 20px rgba(0, 0, 0, 0.3);
}
.search_button{
    all: unset;
    height: 3vmax;
    background-color: #348FE2;
    color: white;
    padding-left: 0.6vmax;
    padding-right: 0.6vmax;
    border: 0.2vmax solid #0056A5;
    cursor: pointer;
    transition: 0.2s;
    display: flex;
    align-items: center;
}
.search_button:hover{
    -webkit-filter: brightness(90%);
            filter: brightness(90%);
}
.search_button:active{
    -webkit-filter: brightness(70%);
            filter: brightness(70%);
}
.search_button p{
    margin-left: 0.5vmax;
}
.filter_button{
    all: unset;
    height: 3vmax;
    background-color: #0056A5;
    color: white;
    padding-left: 1.5vmax;
    padding-right: 1.5vmax;
    border: 0.2vmax solid #0056A5;
    cursor: pointer;
    display: flex;
    align-items: center;
}
.search_divide{
    box-shadow: none;
    border: none;
    height: 0.5vmax;
    background-color: #0056A5;
}

.HPVC {
    left: 6vmax;
}
.hpvc_2020 {
    height: 20vmax;
}
.SAUVC {
    left: 7vmax;
}
.sauvc_2022{
    height: 20vmax;
}
.SDC {
    left: 4.5vmax;
}
.sdc_2019 {
    height: 20vmax;
}
.sdc_2020 {
    height: 21.5vmax;
}
.TCTD {
    left: 5vmax;
}
.tctd_2018 {
    height: 35vmax;
}
.IRC {
    left: 3.5vmax;
}
.irc_image {
    text-align: center;
}
.irc_2017 {
    height: 22vmax;
    margin: 0 1vmax 1vmax 3vmax;
    box-shadow: 0 0 0.8vmax 0 lightgray;
}
.Shrishti {
    left: 8vmax;;
}
.blogs {
    left: 5.5vmax;
}

.blog_card {
    display: flex;
}

.blog_image {
    z-index: 10;
    position: relative;
    left: 5vmax;
    top: 1vmax;
    width: 22vmax;
    height: 18vmax;
    object-fit: cover;
    -webkit-filter: drop-shadow(3px 0px 15px rgba(0, 0, 0, 0.25));
            filter: drop-shadow(3px 0px 15px rgba(0, 0, 0, 0.25));
}

.mobile_blog_img {
    display: none;
}

.blog_content {
    display: flex;
    justify-content: center;
    width: 85%;
    height: 20vmax;
    float: right;
    box-shadow: 0 0 0.8vmax lightgray;
    margin-bottom: 2.5vmax;
}

.blog_box {
    background-color: #348FE2;
    width: 4%;
}

.blog_post {
    width: 96%;
    padding: 1vmax;
    text-align: center;
}

.post {
    font-weight: 500;
    color: #0056A5;
    font-size: 1.7vmax;
    padding-bottom: 2px;
    margin-bottom: 1vmax;
    position: relative;
}
.post:before{
    content: "";
    position: absolute;
    width: 3%;
    height: 1px;
    bottom: 0;
    left: 48.5%;
    border-bottom: 2px solid #0056A5;
}

.blog_title {
    font-size: 1.4vmax;
    margin-bottom: 0.5vmax;
    letter-spacing: 1px;
    padding-left: 4vmax;
}

.blog_date {
    font-size: 1.3vmax;
    margin-bottom: 2vmax;
}

.blog_desc {
    padding-left: 4vmax;
    text-align: left;
    font-size: 1.1vmax;
}

.blog_link {
    all: unset;
    cursor: pointer;
    font-size: 2.5vmax;
}

.more_blogs {
    all: unset;
    background-color: #348FE2;
    color: white;
    float: right;
    cursor: pointer;
    padding: 0.5vmax 16vmax 0.5vmax 4vmax;
    line-height: 1.5;
    font-size: 1.5vmax;
}
.more_blogs:hover, .blog_link:hover {
    font-weight: 600;
}

@media(max-width: 600px){
    .blog_card{
        flex-direction: column;
        align-items: center;
        width: 100%;
    }
    .blog_image{
        display: none;
        left: 2vmax;
    }
    .mobile_blog_img {
        display: block;
    }
    .blog_box{
        display: none;
    }
    .blog_content{
        float: left;
        width: 96%;
        grid-gap: 1rem;
        gap: 1rem;
    }
    .blog_date{
        margin-bottom: 0;
    }
}

@media(max-width: 550px){
    .post{
        display: none;
    }
}

@media(max-width: 500px){
    .blog_content{
        height: 40vmax;
        flex-direction: column;
        align-items: center;
    }
    .blog_image{
        left: 0;
    }
    .blog_desc{
        padding-left: 0;
    }
    .blog_title{
        padding-left: 0;
    }
}
.Footer_footer__181Ag {
  background-color: #000;
  text-align: center;
  padding: 1em;
  display: flex;
  flex-direction: column;
  position: relative;
}
.Footer_contact__UdGSj {
  margin-bottom: 2em;
}
.Footer_contact__UdGSj > h1 {
  font-weight: 700;
  margin: 0;
  color: #00b2ff;
  padding: 1.5em;
}
.Footer_contactBtn__HEzjx {
  font-weight: 590;
  background: transparent;
  color: white;
  border: 1px white solid;
  font-size: 1.5em;
  padding: 0.25em 1.5em 0.25em 1.5em;
  border-radius: 1em;
  text-decoration: none;
}
.Footer_icons4__ewBj2 {
  display: flex;
  grid-gap: 2em;
  gap: 2em;
  justify-content: center;
  align-items: center;
  margin-bottom: 2em;
}
.Footer_icons4__ewBj2 > span {
}
.Footer_icons4__ewBj2 a {
  color: white;
  text-decoration: none;
}
.Footer_copyright__1TYWR {
  font-weight: 400;
  color: #ffffffbd;
  font-size: 0.7em;
}
.Footer_icon1__MUprz {
  font-size: 2em;
}
.Footer_mediumIcon__2yasM {
  padding-top: 0.4em;
  font-size: 2em;
}
.Footer_backtotop__2lJO6 {
  height: 3vmax;
  width: 3vmax;
  position: absolute;
  left: 95%;
  bottom: 5%;
  cursor: pointer;
}
@media screen and (max-width: 550px) {
  .Footer_backtotop__2lJO6 {
    left: 90%;
  }
}

.resources {
    left: 10vmax;
}

.resources_link {
    display: flex;
    padding-left: 3vmax;
    flex-direction: column;
    grid-gap: 0.5vmax;
    gap: 0.5vmax;
}

.resources_link>a {
    text-decoration: none;
    color: black;
    font-size: 1.5vmax;
}

.resources_link>a:hover {
    font-weight: 600;
}


.containerInside{
    border: 2px solid #00B2FF;
    background-color: #EAF5FF;
    height: 45%;
    display: flex;
    flex-direction: row;
    border-radius: 1rem;
    padding: 1rem;
    grid-gap:5rem;
    gap:5rem;
    margin: 2rem;
}
.resourceImage{
    border: 5px solid #01B0FC;
    width: 15rem;
    height: 15rem;
}

.resourceInfo
{

    width: 80%;
    padding: .1rem;
    grid-gap: .5rem;
    gap: .5rem;
    display: flex;
    flex-direction: column;
}
.resourceHeading{
    width: 100%;
    height: 15%;
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 1.7vmax;
    margin-top: -.8rem;

}

.resourceDescription{
    width: 100%;
    height: 60%;
    opacity: 70%;
}
.resourceDownload{
    width: 100%;
    height: 15%;
    display: flex;
    align-items: center;
    padding: .2rem;
}
.compendium{
    height: 100%;
    width: 100%;
}

.studyMaterial{
    height: 100%;
    width: 100%;
}
.btn{
    height: 100%;
    width: 23%;
    background-color: #EAF5FF;
    border: 2px solid #00B2FF;
    border-radius: .5rem;
    color:#00B2FF;
    font-weight:bolder;
    cursor: pointer;
    font-size: 90%;
    text-decoration: none;
    margin-top: -2rem;


}


.btn:hover{
    border: 4px solid #00B2FF;
    transition: .8s;
    font-size: 100%;
    text-decoration: none;
    color: #00B2FF;
}
.gallery_gear{
    left: 7.5vmax;
}
.gallery_heading{
    font-weight: 500;
    font-size: 1.7vmax;
    line-height: 2.4vmax;
    color: #348FE2;
}
.gallery_card{
    display: flex;
    flex-direction: column;
    background: #00B2FF;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    -webkit-filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.3));
            filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.3));
}
.gallery_card_img_p{
    background: #00B2FF;
    padding-top: 0.45vmax;
}
.gallery_card_img_p img{
    width: 20vmax;
    object-fit: cover;
    height: 14.28vmax;
}
.gallery_card_heading{
    font-weight: 500;
    font-size: 1.5vmax;
    color: #FFFFFF;
    padding: 0.3vmax 1vmax;
}
.gallery_parent_swiper .swiper {
    width: calc(100% - 92px);
    height: 100%;
    /* margin: 0px 46px; */
}  
.gallery_parent_swiper .swiper-slide {
    width: 20vmax !important;
    margin: 0 1vmax !important;
}
.gallery_parent_swiper .swiper-container{
    position: relative;
    background: #FBFBFB !important;
    padding: 2.1vmax 2.4vmax;
    background: #FBFBFB;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
    margin-top: 1.25vmax;
    margin-bottom: 5.6vmax;
    overflow-y: hidden !important;
}
.gallery_parent_swiper .swiper-button-prev, .gallery_parent_swiper .swiper-button-next{
    color: #348FE2 !important;
    padding: 10px;
    aspect-ratio: 1/1;
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    transition: 0.3s;
    opacity: 1 !important;
    pointer-events: all !important;
}
.gallery_parent_swiper .swiper-button-prev{
    height: calc(150% + 4.5vmax);
    background: #F1F1F1 !important;
    -webkit-transform: scale(0.8) translateY(calc(-50% - 1.8vmax)) translateX(-42%);
            transform: scale(0.8) translateY(calc(-50% - 1.8vmax)) translateX(-42%);
    width: 4.2vmax;
}
.gallery_parent_swiper .swiper-button-next{
    height: calc(150% + 4.5vmax);
    background: #F1F1F1 !important;
    -webkit-transform: scale(0.8) translateY(calc(-50% - 1.8vmax)) translateX(42%);
            transform: scale(0.8) translateY(calc(-50% - 1.8vmax)) translateX(42%);
    width: 4.2vmax;
}
.gallery_parent_swiper .swiper-button-prev::after, .gallery_parent_swiper .swiper-button-next::after{
    -webkit-transform: scale(0.6);
            transform: scale(0.6);
    aspect-ratio: 1/1;
}
* {
  box-sizing: border-box;
}
.ContactUs_contactUs_gear__12DmO {
  left: 11.7vmax;
}
.ContactUs_details__1YHjb {
  display: flex;
  grid-gap: 3em;
  gap: 3em;
  flex-wrap: wrap;
}
.ContactUs_msg__rY6oX {
  flex: 0.6 1;
}
.ContactUs_address__2eqEU {
  flex: 0.4 1;
}
.ContactUs_form__11RXV ::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.5);
}
.ContactUs_form__11RXV :-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.5);
}
.ContactUs_form__11RXV ::placeholder {
  color: rgba(0, 0, 0, 0.5);
}
input,
textarea {
  font-family: Cerebri Sans Book;
  overflow: hidden;
  font-weight: 274;
  font-size: 1.2em;
  outline: none;
  background: transparent;
  padding: 0.5em 1.3em 0.5em 1.3em;
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  width: 100%;
  color: rgb(0, 0, 0);
}
input:focus,
textarea:focus {
  border: 2px solid black;
}

.ContactUs_form__11RXV {
  display: flex;
  flex-direction: column;
  grid-gap: 1em;
  gap: 1em;
  padding: 2em 3em 2em 3em;
  background: #eaf5ff;
  border-radius: 20px;
}
.ContactUs_nameAndEmail__2gSoY {
  display: flex;
  width: 100%;
  grid-gap: 1em;
  gap: 1em;
}
.ContactUs_subAndMsg__2CLC- {
  display: flex;
  flex-direction: column;
  grid-gap: 1em;
  gap: 1em;
}
.ContactUs_buttons__3AFvn {
  display: flex;
  justify-content: space-evenly;
}
.ContactUs_buttons__3AFvn > input {
  width: auto;
  background: transparent;
  color: #00b2ff;
  outline: none;
  border: 2px solid #00b2ff;
  border-radius: 12px;
  font-weight: 400;
  padding: 0.3em 1em 0.3em 1em;
}
.ContactUs_buttons__3AFvn > input:nth-child(1) {
  padding: 0.3em 3em 0.3em 3em;
}
.ContactUs_buttons__3AFvn > input:hover {
  background-color: #00b2ff;
  color: white;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.ContactUs_asmeContact_details__IFJmL {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 1em;
  gap: 1em;
  margin-top: 2em;
  position: relative;
}
.ContactUs_asmeContact_details__IFJmL::after {
  content: "";
  background-color: #00b2ff;
  position: absolute;
  left: 49%;
  width: 2px;
  height: 120%;
  z-index: 100;
}
.ContactUs_asmeContact_details__IFJmL a,
.ContactUs_asmeContact_details__IFJmL p {
  font-weight: 400;
  font-size: 16px;
}
.ContactUs_number__29PLn,
.ContactUs_email__1-8x2 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ContactUs_number__29PLn > div,
.ContactUs_email__1-8x2 > div {
  display: flex;
  flex-direction: column;
  grid-gap: 0.7em;
  gap: 0.7em;
}
.ContactUs_icons__2elqH {
  background-color: #00b2ff;
  color: white;
  font-size: 3vmax;
  padding: 0.2em;
  border-radius: 50%;
  margin-right: 1em;
}
.ContactUs_asmeContact_details__IFJmL a {
  text-decoration: none;
  color: rgba(38, 38, 38, 0.74);
}
.ContactUs_address__2eqEU {
  display: flex;
  flex-direction: column;
}

.ContactUs_snapshot__3o0Hf {
  display: flex;
  flex: 0.9 1;
  margin-bottom: 2em;
}
.ContactUs_address_details__3xd60 {
  flex: 0.1 1;
}
.ContactUs_img__24N1A,
.ContactUs_snapshot__3o0Hf iframe {
  width: 100%;
  height: 100%;
}
.ContactUs_snapshot__3o0Hf iframe {
  border-radius: 8px;
}
.ContactUs_address_details__3xd60 > h2,
.ContactUs_address_details__3xd60 > p {
  font-style: normal;
  font-weight: 400;
}
.ContactUs_address_details__3xd60 > h2 {
  color: #348fe2;
  font-size: 2.4vmax;
  margin-bottom: 0.5em;
}
.ContactUs_address_details__3xd60 > p {
  font-size: 2vmax;
  margin-bottom: 2em;
}
p {
  padding: 0;
  margin: 0;
}

@media screen and (max-width: 700px) {
  .ContactUs_details__1YHjb {
    flex-direction: column;
  }
  .ContactUs_address__2eqEU {
    flex-direction: column-reverse;
  }
  .ContactUs_snapshot__3o0Hf iframe {
    height: 300px;
  }
  .ContactUs_buttons__3AFvn > input:nth-child(1) {
    padding: 0.3em 2em 0.3em 2em;
  }
}
@media screen and (max-width: 400px) {
  .ContactUs_snapshot__3o0Hf iframe {
    height: 200px;
  }
  input,
  textarea {
    font-size: 0.8rem;
  }
}
@media screen and (max-width: 500px) {
  .ContactUs_form__11RXV {
    padding-left: 2em;
    padding-right: 2em;
  }
  .ContactUs_buttons__3AFvn > input {
    padding: 0.3em 0.5em 0.3em 0.5em;
  }
  input,
  textarea {
    padding: 0.5em 0.8em 0.5em 0.8em;
  }
  .ContactUs_contactUsPage__1m_cY {
    padding: 1em 1em 2em 1em;
  }
  .ContactUs_nameAndEmail__2gSoY {
    flex-direction: column;
  }
}
@media screen and (max-width: 340px) {
  .ContactUs_asmeContact_details__IFJmL::after {
    left: 0;
    height: 2px;
    width: 100%;
  }
  .ContactUs_asmeContact_details__IFJmL {
    flex-direction: column;
  }
  .ContactUs_buttons__3AFvn > input:nth-child(1) {
    padding: 0.3em 0.5em 0.3em 0.5em;
  }
}

