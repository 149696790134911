.team_parent{
    position: relative;
    width: 100vw;
}
.team_svg_parent{
    display: flex;
    align-items: flex-end;
}
.team_svg_parent svg{
    height: 2.5vmax;
    position: relative;
    /* z-index: -1; */
    top: 1.3vmax;
    left: -1vmax;
    /* transform: translate(-1.5vmax, 1.6vmax); */
}
.team_of_2019_20{
    font-style: normal;
    font-size: 2.4vmax;
    font-weight: 600;
    margin-left: 46px;
    margin-top: 46px;
}

.team_parent_swiper .swiper {
    width: calc(100% - 92px);
    height: 100%;
    margin: 0px 46px;
}  
.team_parent_swiper .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    position: relative;
    top: 50px;
}
.team_parent_swiper .swiper-slide img {
    display: block;
    width: 100%;
    height: 33vmax;
    object-fit: cover;
    position: relative;
}
.team_parent_swiper .swiper-container{
    margin: 0px 46px !important;
    /* background: url(500_F_225403009_RGr662fPwQPR6guugsPIhMhnLNjWXjUp.jpg); */
filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.25));
}
.team_mem_card_heading{
    font-style: normal;
    font-weight: 500;
    /* font-size: 25px; */
    font-size: 1.9vmax;
    line-height: 32px;
    color: #348FE2;
    margin: 0px 46px;
    margin-top: 40px;
    margin-bottom: 16px;
}
.team_mem_card_parent{
    margin: 0px 46px;
    display: flex;
    flex-wrap: wrap;
    max-width: 100vw;
    justify-content: space-between;
}
.team_parent_swiper .swiper-button-prev, .team_parent_swiper .swiper-button-next{
    background: #DDDDDD;
    color: #fff !important;
    padding: 10px;
    border-radius: 50%;
    aspect-ratio: 1/1;
    transform: scale(0.8);
    transition: 0.3s;
    height: 4vmax !important;
    width: 4vmax !important;
    margin-top: 0 !important;
}
.team_parent_swiper .swiper-button-prev:hover, .team_parent_swiper .swiper-button-next:hover{
    box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.411);
    filter: brightness(70%);
}
.team_parent_swiper .swiper-button-prev:active, .team_parent_swiper .swiper-button-next:active{
    filter: brightness(60%);
}
.team_parent_swiper .swiper-button-prev::after, .team_parent_swiper .swiper-button-next::after{
    transform: scale(0.6);
    aspect-ratio: 1/1;
}

.team_sep-render{
    margin: 0px 46px;
    display: flex;
    flex-wrap: wrap;
    max-width: 100vw;
    /* justify-content: left; */
}



