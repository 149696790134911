.IRC {
    left: 3.5vmax;
}
.irc_image {
    text-align: center;
}
.irc_2017 {
    height: 22vmax;
    margin: 0 1vmax 1vmax 3vmax;
    box-shadow: 0 0 0.8vmax 0 lightgray;
}