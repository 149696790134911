.footer {
  background-color: #000;
  text-align: center;
  padding: 1em;
  display: flex;
  flex-direction: column;
  position: relative;
}
.contact {
  margin-bottom: 2em;
}
.contact > h1 {
  font-weight: 700;
  margin: 0;
  color: #00b2ff;
  padding: 1.5em;
}
.contactBtn {
  font-weight: 590;
  background: transparent;
  color: white;
  border: 1px white solid;
  font-size: 1.5em;
  padding: 0.25em 1.5em 0.25em 1.5em;
  border-radius: 1em;
  text-decoration: none;
}
.icons4 {
  display: flex;
  gap: 2em;
  justify-content: center;
  align-items: center;
  margin-bottom: 2em;
}
.icons4 > span {
}
.icons4 a {
  color: white;
  text-decoration: none;
}
.copyright {
  font-weight: 400;
  color: #ffffffbd;
  font-size: 0.7em;
}
.icon1 {
  font-size: 2em;
}
.mediumIcon {
  padding-top: 0.4em;
  font-size: 2em;
}
.backtotop {
  height: 3vmax;
  width: 3vmax;
  position: absolute;
  left: 95%;
  bottom: 5%;
  cursor: pointer;
}
@media screen and (max-width: 550px) {
  .backtotop {
    left: 90%;
  }
}
