.resources {
    left: 10vmax;
}

.resources_link {
    display: flex;
    padding-left: 3vmax;
    flex-direction: column;
    gap: 0.5vmax;
}

.resources_link>a {
    text-decoration: none;
    color: black;
    font-size: 1.5vmax;
}

.resources_link>a:hover {
    font-weight: 600;
}


.containerInside{
    border: 2px solid #00B2FF;
    background-color: #EAF5FF;
    height: 45%;
    display: flex;
    flex-direction: row;
    border-radius: 1rem;
    padding: 1rem;
    gap:5rem;
    margin: 2rem;
}
.resourceImage{
    border: 5px solid #01B0FC;
    width: 15rem;
    height: 15rem;
}

.resourceInfo
{

    width: 80%;
    padding: .1rem;
    gap: .5rem;
    display: flex;
    flex-direction: column;
}
.resourceHeading{
    width: 100%;
    height: 15%;
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 1.7vmax;
    margin-top: -.8rem;

}

.resourceDescription{
    width: 100%;
    height: 60%;
    opacity: 70%;
}
.resourceDownload{
    width: 100%;
    height: 15%;
    display: flex;
    align-items: center;
    padding: .2rem;
}
.compendium{
    height: 100%;
    width: 100%;
}

.studyMaterial{
    height: 100%;
    width: 100%;
}
.btn{
    height: 100%;
    width: 23%;
    background-color: #EAF5FF;
    border: 2px solid #00B2FF;
    border-radius: .5rem;
    color:#00B2FF;
    font-weight:bolder;
    cursor: pointer;
    font-size: 90%;
    text-decoration: none;
    margin-top: -2rem;


}


.btn:hover{
    border: 4px solid #00B2FF;
    transition: .8s;
    font-size: 100%;
    text-decoration: none;
    color: #00B2FF;
}